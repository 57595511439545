<template>
    <header :class="{ 'navopened': navcheck }">
        <Toast />
        <div class="header" :class="{ 'comeDown': isDown, 'comeUp': isUp }">
            <div class="container">
                <div class="d-flex align-items-center justify-content-between flex-wrap">
                    <div class="M_end_30">
                        <router-link to="/"><img class="logo" :src="`https://backend.cosmo-land.com/${logo}`"
                                alt=""></router-link>
                    </div>
                    <div v-if="!navBarMedia" class="navBar">
                        <ul>
                            <li><router-link to="/">{{ $t('layout.home') }}</router-link></li>
                            <li><router-link to="/ProductsView">{{ $t('layout.products') }}</router-link></li>
                            <li><router-link to="/OffersView">{{ $t('layout.offers') }}</router-link></li>
                            <li><router-link to="/categoryView">{{ $t('layout.categories') }}</router-link></li>
                            <li><router-link to="/FavoriteView">{{ $t('layout.favorite') }}</router-link></li>
                            <!-- <li><router-link to="">{{ $t('layout.Contact') }}</router-link></li> -->
                        </ul>
                    </div>
                    <div class="d-flex align-items-center gap25">
                        <router-link v-badge.danger="cart_count" to="/CartView">
                            <span>
                                <img class="width25" :src="require('@/assets/images/shopping-cart.png')" alt="">
                            </span>
                        </router-link>
                        <router-link v-badge.danger="notify_count" to="/notification" v-if="is_login">
                            <span>
                                <img class="width25" :src="require('@/assets/images/notification.png')" alt="">
                            </span>
                        </router-link>
                        <div class="dropdown" ref="ignoreElRef" v-if="is_login">
                            <button class="btn font13 dropdownToggle" @click.stop="dropShown = !dropShown" type="button">
                                <div class="d-flex align-items-center gap10">
                                    <img class="avatar" :src="profile_data.image" alt="">
                                    <span :class="{ 'd-none': navBarMedia }">{{ profile_data.full_name }}</span>
                                    <span class="mainColor font13" :class="{ 'd-none': navBarMedia }"><font-awesome-icon
                                            :icon="['fas', 'chevron-down']" /></span>
                                </div>
                            </button>
                            <Transition name="dropShow">
                                <ul v-if="dropShown" v-on-click-outside="onClickOutsideHandler" class="dropdown-menu">
                                    <li>
                                        <router-link class="dropdown-item defualt-link" @click.stop="dropShown = !dropShown"
                                            to="/profile">
                                            <div class="d-flex align-items-center gap18">
                                                <img class="user-icon" :src="require('@/assets/images/user_info.png')"
                                                    alt="">
                                                <span class="font11 user-txt">{{ $t('layout.profile') }}</span>
                                                <span class="font9 float-arrow"><font-awesome-icon
                                                        :icon="['fas', 'chevron-left']" /></span>
                                            </div>
                                        </router-link>
                                    </li>
                                    <li>
                                        <router-link class="dropdown-item defualt-link" @click.stop="dropShown = !dropShown"
                                            to="/myAddresses">
                                            <div class="d-flex align-items-center gap18">
                                                <img class="user-icon" :src="require('@/assets/images/location.png')"
                                                    alt="">
                                                <span class="font10 user-txt">{{ $t('layout.my_adresses') }}</span>
                                                <span class="font9 float-arrow"><font-awesome-icon
                                                        :icon="['fas', 'chevron-left']" /></span>
                                            </div>
                                        </router-link>
                                    </li>
                                    <li>
                                        <router-link class="dropdown-item defualt-link" @click.stop="dropShown = !dropShown"
                                            to="/myOrders">
                                            <div class="d-flex align-items-center gap18">
                                                <img class="user-icon" :src="require('@/assets/images/shopping-bag.png')"
                                                    alt="">
                                                <span class="font10 user-txt">{{ $t('layout.header.myOrders') }}</span>
                                                <span class="font9 float-arrow"><font-awesome-icon
                                                        :icon="['fas', 'chevron-left']" /></span>
                                            </div>
                                        </router-link>
                                    </li>
                                    <!-- <li>
                                        <router-link class="dropdown-item defualt-link" @click.stop="dropShown = !dropShown"
                                            to="/wallet">
                                            <div class="d-flex align-items-center gap18">
                                                <img class="user-icon" :src="require('@/assets/images/wallet.png')" alt="">
                                                <span class="font10 user-txt">{{ $t('layout.header.wallet') }}</span>
                                                <span class="font9 float-arrow"><font-awesome-icon
                                                        :icon="['fas', 'chevron-left']" /></span>
                                            </div>
                                        </router-link>
                                    </li> -->
                                    <li>
                                        <router-link class="dropdown-item defualt-link" @click.stop="dropShown = !dropShown"
                                            to="/HelpCenter">
                                            <div class="d-flex align-items-center gap18">
                                                <img class="user-icon" :src="require('@/assets/images/question.png')"
                                                    alt="">
                                                <span class="font10 user-txt">{{ $t('layout.header.help_center') }}</span>
                                                <span class="font9 float-arrow"><font-awesome-icon
                                                        :icon="['fas', 'chevron-left']" /></span>
                                            </div>
                                        </router-link>
                                    </li>
                                    <li>
                                        <button @click="sign_out" class="dropdown-item defualt-link"
                                            @click.stop="dropShown = !dropShown">
                                            <div class="d-flex align-items-center gap18">
                                                <img class="width25" :src="require('@/assets/images/logout.png')" alt="">
                                                <span class="font10 user-txt">{{ $t('layout.header.logOut') }}</span>
                                                <span class="font9 float-arrow"><font-awesome-icon
                                                        :icon="['fas', 'chevron-left']" /></span>
                                            </div>
                                        </button>
                                    </li>
                                    <li class="lang P_start_18 P_end_18 P_top_10 P_bottom_10">
                                        <div class="switch-lang"
                                            :class="{ 'ar-act': locale == 'ar', 'en-act': locale == 'en' }">
                                            <button @click="switchLangAr"
                                                class="btn d-flex align-items-center gap10 p-0 ar">
                                                <img class="width25" :src="require('@/assets/images/langSvg/arFlag.svg')"
                                                    alt="">
                                                <span class="font10">العربيه</span>
                                            </button>
                                            <button @click="switchLangEn"
                                                class="btn d-flex align-items-center gap10 p-0 en">
                                                <span class="font10">English</span>
                                                <img class="width25" :src="require('@/assets/images/langSvg/enFlag.svg')"
                                                    alt="">
                                            </button>
                                        </div>
                                    </li>
                                </ul>
                            </Transition>
                        </div>
                        <div class="dropdown" ref="ignoreElRef" v-if="!is_login">
                            <button class="btn font13 dropdownToggle" @click.stop="dropShown = !dropShown" type="button">
                                <div class="flex align-items-center gap10">
                                    <span>{{ $t('layout.lang') }}</span>
                                    <span class="mainColor"><font-awesome-icon :icon="['fas', 'globe-africa']" /></span>
                                </div>
                            </button>
                            <Transition name="dropShow">
                                <ul v-if="dropShown" v-on-click-outside="onClickOutsideHandler" class="dropdown-menu switch">
                                    <li class="lang P_start_18 P_end_18 P_top_10 P_bottom_10">
                                        <div class="switch-lang"
                                            :class="{ 'ar-act': locale == 'ar', 'en-act': locale == 'en' }">
                                            <button @click="switchLangAr"
                                                class="btn d-flex align-items-center gap10 p-0 ar">
                                                <img class="width25" :src="require('@/assets/images/langSvg/arFlag.svg')"
                                                    alt="">
                                                <span class="font10">العربيه</span>
                                            </button>
                                            <button @click="switchLangEn"
                                                class="btn d-flex align-items-center gap10 p-0 en">
                                                <span class="font10">English</span>
                                                <img class="width25" :src="require('@/assets/images/langSvg/enFlag.svg')"
                                                    alt="">
                                            </button>
                                        </div>
                                    </li>
                                </ul>
                            </Transition>
                        </div>
                        <div class="flex gap10 align-items-center">
                            <router-link v-if="!is_login" class="btn main_btn up" to="/SiteLogin">{{
                                $t('layout.auth.login') }}</router-link>
                            <div class="position-relative height20">
                                <input v-if="navBarMedia" v-model="navcheck" @change="handelCheck" type="checkbox"
                                    id="navcheck" role="button" title="menu">
                                <label v-if="navBarMedia" class="navcheckLabel" for="navcheck" aria-hidden="true"
                                    title="menu">
                                    <span class="burger">
                                        <span class="bar">
                                            <span class="visuallyhidden"></span>
                                        </span>
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>

    <div class="backLayer" @click="navcheck = false"></div>
    <div v-if="navBarMedia" class="navBarMedia">
        <ul>
            <li><router-link @click="navcheck = false" to="/">{{ $t('layout.home') }}</router-link></li>
            <li><router-link @click="navcheck = false" to="/ProductsView">{{ $t('layout.products') }}</router-link></li>
            <li><router-link @click="navcheck = false" to="/OffersView">{{ $t('layout.offers') }}</router-link></li>
            <li><router-link @click="navcheck = false" to="/categoryView">{{ $t('layout.categories') }}</router-link></li>
            <li><router-link @click="navcheck = false" to="/FavoriteView">{{ $t('layout.favorite') }}</router-link></li>
            <!-- <li><router-link @click="navcheck = false" to="">{{ $t('layout.header.Contact') }}</router-link></li> -->
        </ul>
    </div>
</template>

<script setup>
// ============================== imports
import { onMounted, ref, watch } from 'vue';
import { vOnClickOutside } from '@vueuse/components'
import { useRoute } from 'vue-router';
import axios from 'axios';
import { response } from '@/response';
import { tost_handel } from '@/ValidTost'
const { notify_toast } = tost_handel()
import { useRouter } from 'vue-router';
// import axios from 'axios';


// ============================== data
const ignoreElRef = ref()
const dropShown = ref(null)
const useRout = useRoute()
const ls = ref(0);
const isDown = ref(false)
const isUp = ref(false)
const is_login = ref(false)
const navBarMedia = ref(null)
const navcheck = ref(null)
const profile_data = ref([])
const routeOtp = useRouter()
const notify_count = ref()
const cart_count = ref()
const logo = ref()

// ======================== methods

const onClickOutsideHandler = [
    () => {
        dropShown.value = false
    },
    { ignore: [ignoreElRef] }
]


// mediaHandller javascript
window.addEventListener("scroll", function () {
    let win = window.scrollTop || document.documentElement.scrollTop;
    if (win > ls.value) {
        isUp.value = true
        isDown.value = false
    } else {
        isDown.value = true
        isUp.value = false
    }
    ls.value = win;
});


// media hanell 
const mediaHandller = (media) => {
    if (media.matches) {
        navBarMedia.value = true
    } else {
        navBarMedia.value = false
    }
}
var media = window.matchMedia("(max-width: 992px)")
mediaHandller(media)
media.addListener(mediaHandller)



// language switch 
let lang = 'ar';
const locale = ref(localStorage.getItem('locale'));

function switchLangAr() {
    lang = 'ar';
    locale.value = 'ar'
    if (localStorage.getItem('locale')) {
        localStorage.removeItem('locale');
    }

    localStorage.setItem('locale', lang);
    location.reload();
}
// switchLangEn
function switchLangEn() {
    lang = 'en';
    locale.value = 'en'
    if (localStorage.getItem('locale')) {
        localStorage.removeItem('locale');
    }

    localStorage.setItem('locale', lang);
    location.reload();
    console.log(localStorage.getItem('locale'));
}

// =========== get profile 
const get_profile = async () => {
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
    }
    await axios.get('profile', config).then((res) => {
        let status = response(res).status
        let data = response(res).data
        if (status === 'success') {
            profile_data.value = data
        }
    })
}

// =========== get profile 
const get_logo = async () => {
    await axios.get('settings').then((res) => {
        let status = response(res).status
        let data = response(res).data
        if (status === 'success') {
            logo.value = data.logo
        }
    })
}

// =========== get notify count 
const get_count = async () => {
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
    }
    await axios.get('count-cart-and-notifications', config).then((res) => {
        let status = response(res).status
        let data = response(res).data
        if (status === 'success') {
            notify_count.value = data.notification
            cart_count.value = data.cart_count
        }
    })
}

//  ========== sign out 
const sign_out = async () => {
    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
    }
    await axios.delete('sign-out', config).then((res) => {
        let status = response(res).status
        let msg = response(res).msg
        if (status == 'success') {
            notify_toast(msg, 'success')
            localStorage.removeItem("token")
            routeOtp.push({ name: 'SiteLogin' })

        } else if (status === 'unauthenticated') {
            localStorage.removeItem("token")
            localStorage.removeItem("userId")
            notify_toast(msg, 'error')
            routeOtp.push({ name: 'home' })
        } else {
            notify_toast(msg, 'error')
        }
    }).catch((e) => {
        console.log(e);
    })
}

// =================================== lifecycle hoocks 

// ===================== watch
watch(useRout, () => {
    get_profile()
    const localeToken = localStorage.getItem('token');
    if (localeToken) {
        is_login.value = true;
    } else {
        is_login.value = false;
    }
});

// ================== on mounted

onMounted(() => {
    get_count()
    get_logo()
    get_profile()
    const localeToken = localStorage.getItem('token');
    if (localeToken) {
        is_login.value = true;
    } else {
        is_login.value = false;
    }
})

</script>

<style>
.dropShow-enter-active {
    animation: scale-in 0.2s forwards;
}

.dropShow-leave-active {
    animation: scale-out 0.2s forwards;
}

@keyframes scale-in {
    0% {
        transform: scale(.95);
        opacity: 0;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes scale-out {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    100% {
        transform: scale(.95);
        opacity: 0;
    }
}
</style>