import { createRouter, createWebHistory } from 'vue-router'
import i18n from "@/i18n.js";

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/HomeView.vue'),
    meta: { title: 'layout.home' }
  },
  {
    path: '/ProductsView',
    name: 'ProductsView',
    component: () => import('@/views/ProductsView.vue'),
    meta: { title: 'layout.products' }
  },
  {
    path: '/categoryView',
    name: 'categoryView',
    component: () => import('@/views/categoryView.vue'),
    meta: { title: 'layout.categories' }
  },
  {
    path: '/CategoryViewProducts/:id',
    name: 'CategoryViewProducts',
    component: () => import('@/views/CategoryViewProducts.vue'),
    meta: { title: 'layout.products' }
  },
  {
    path: '/FavoriteView',
    name: 'FavoriteView',
    component: () => import('@/views/FavoriteView.vue'),
    meta: { title: 'layout.favorite' }
  },
  {
    path: '/OffersView',
    name: 'OffersView',
    component: () => import('@/views/OffersView.vue'),
    meta: { title: 'layout.offers' }
  },
  {
    path: '/productDetails/:id',
    name: 'productDetails',
    component: () => import('@/views/productDetails.vue'),
    meta: { title: 'layout.products_details' }
  },
  {
    path: '/SiteLogin',
    name: 'SiteLogin',
    component: () => import('@/components/auth/SiteLogin.vue'),
    meta: { title: 'layout.auth.login' }
  },
  {
    path: '/forgetPassword',
    name: 'forgetPassword',
    component: () => import('@/components/auth/forgetPassword.vue'),
    meta: { title: 'layout.auth.login' }
  },
  {
    path: '/resetPassword',
    name: 'resetPassword',
    component: () => import('@/components/auth/resetPassword.vue'),
    meta: { title: 'layout.auth.login' }
  },
  {
    path: '/SiteRegister',
    name: 'SiteRegister',
    component: () => import('@/components/auth/SiteRegister.vue'),
    meta: { title: 'layout.auth.registration' }
  },
  {
    path: '/ActivateAccount',
    name: 'ActivateAccount',
    component: () => import('@/components/auth/ActivateAccount.vue'),
    meta: { title: 'layout.auth.registration' }
  },
  {
    path: '/CartView',
    name: 'CartView',
    component: () => import('@/views/CartView.vue'),
    meta: { title: 'layout.cart' }
  },
  {
    path: '/notification',
    name: 'notification',
    component: () => import('@/views/NotificationView.vue'),
    meta: { title: 'layout.header.notification' }
  },
  {
    path: '/OrderDetails/:id',
    name: 'OrderDetails',
    component: () => import('@/views/OrderDetails.vue'),
    meta: { title: 'orders.orderDetails' }
  },
  {
    path: '/AddNewAddress',
    name: 'AddNewAddress',
    component: () => import('@/views/my_addresses/AddNewAddress.vue'),
    meta: { title: 'layout.add_new_address' }
  },
  {
    path: '/editAddress/:id',
    name: 'editAddress',
    component: () => import('@/views/my_addresses/edit_address.vue'),
    meta: { title: 'layout.edit_address' }
  },
  {
    path: '/myAddresses',
    name: 'myAddresses',
    component: () => import('@/views/my_addresses/my_addresses.vue'),
    meta: { title: 'layout.my_adresses' }
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/profile/ProfileView.vue'),
    meta: { title: 'layout.profile' }
  },
  {
    path: '/changePhone',
    name: 'changePhone',
    component: () => import('@/views/profile/ChangePhone.vue'),
    meta: { title: 'profile.changePhone' }
  },
  {
    path: '/CheckPassword',
    name: 'CheckPassword',
    component: () => import('@/views/profile/CheckPassword.vue'),
    meta: { title: 'profile.changePhone' }
  },
  {
    path: '/ActiveCode',
    name: 'ActiveCode',
    component: () => import('@/views/profile/ActiveCode.vue'),
    meta: { title: 'profile.changePhone' }
  },
  {
    path: '/myOrders',
    name: 'myOrders',
    component: () => import('@/views/MyOrders.vue'),
    meta: { title: 'layout.header.myOrders' }
  },
  // {
  //   path: '/wallet',
  //   name: 'wallet',
  //   component: () => import('@/views/WalletView.vue'),
  //   meta: { title: 'layout.header.wallet' }
  // },
  {
    path: '/HelpCenter',
    name: 'HelpCenter',
    meta: {
      authRout: true
    },
    component: () => import('@/views/HelpCenter/HelpLayout.vue'),
    // meta: { title: 'Auth - Basement' }
    children: [
      {
        path: '/HelpCenter',
        name: 'ContactUsCenter',
        meta: { title: 'layout.Contact' },
        component: () => import('@/views/HelpCenter/ContactUsCenter.vue'),
      },
      {
        path: '/FaqsView',
        name: 'FaqsView',
        meta: { title: 'layout.header.fqs' },
        component: () => import('@/views/HelpCenter/FaqsView.vue'),
      },
      {
        path: '/AboutCenter',
        name: 'AboutCenter',
        meta: { title: 'layout.header.about' },
        component: () => import('@/views/HelpCenter/AboutCenter.vue'),
      },
      {
        path: '/TermView',
        name: 'TermView',
        meta: { title: 'layout.header.termsConditions' },
        component: () => import('@/views/HelpCenter/TermView.vue'),
      },
    ],
  },
  {
    path: '/AboutView',
    name: 'AboutView',
    component: () => import('@/views/Quick_links/AboutView.vue'),
    meta: { title: 'layout.header.about' }
  },
  {
    path: '/ContactUs',
    name: 'ContactUs',
    component: () => import('@/views/Quick_links/ContactUs.vue'),
    meta: { title: 'layout.Contact' }
  },
  {
    path: '/Faqs',
    name: 'Faqs',
    component: () => import('@/views/Quick_links/FaqsView.vue'),
    meta: { title: 'layout.header.fqs' }
  },
  {
    path: '/Terms',
    name: 'Terms',
    component: () => import('@/views/Quick_links/TermView.vue'),
    meta: { title: 'layout.header.termsConditions' }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'notFound',
    meta: { title: 'layout.notFound' },
    component: () => import('@/views/notFound.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  document.title = i18n.global.t(`${to.meta.title}`);
  next()
})

router.afterEach(() => {
  window.scrollTo(0, 0)
})



// router.afterEach((to) => {
//   setTimeout(() => {
//       if (to.hash) {
//           let top = document.querySelector(to.hash).offsetTop;
//           window.scrollTo(0, top - 70);
//       } else {
//           window.scrollTo(0, 0);
//       }
//   }, 500);
// });

export default router
