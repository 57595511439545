
export function response(res) {
    let data = res.data.data
    let status = res.data.key
    let msg = res.data.msg
    if (status === 'success') {
        return {
            status: 'success',
            data: data,
            msg: msg
        }
    } else if (status === 'fail') {
        return {
            status: 'fail',
            msg: msg
        }
    }
    else if (status === 'unauthenticated') {
        return {
            status: 'unauthenticated',
        }
    } 
    else if (status === 'blocked' || status === 'needActive') {
        // ddd 
    } 
    else {
        return false;
    }
}

