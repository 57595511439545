export default {
  "layout": {
    "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not found"])},
    "backHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to home"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products"])},
    "more_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More products"])},
    "more_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More categories"])},
    "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories"])},
    "add_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to cart"])},
    "favorite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favorite"])},
    "offers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["offers"])},
    "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View"])},
    "get_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get offer"])},
    "Contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us"])},
    "my_adresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My adresses"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
    "quickLinks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quick links"])},
    "contactInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact info"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download app"])},
    "filterPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter by price"])},
    "filterBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter by"])},
    "filterUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Highest price"])},
    "filterDown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lowest price"])},
    "filterRating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter by rate"])},
    "filterCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter by category"])},
    "products_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products details"])},
    "cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cart"])},
    "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
    "Quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity"])},
    "total_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total price"])},
    "add_new_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new address"])},
    "edit_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit address"])},
    "choose_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose address"])},
    "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["location"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["select"])},
    "address_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address name"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Addresses"])},
    "address_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address details"])},
    "set_default_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set as default address"])},
    "add_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add address"])},
    "select_pay_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select pay method"])},
    "Pay_recieving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement when recieving"])},
    "Pay_electronic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment by electronic card"])},
    "complete_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete order"])},
    "shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["shipping"])},
    "tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tax"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "no_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No products"])},
    "similar_products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Similar products"])},
    "enter_coupon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert coupon"])},
    "have_coupon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you have a coupon?"])},
    "no_notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no notifications"])},
    "remove_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove all"])},
    "online_trans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Add balance online "])},
    "complain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["complain"])},
    "suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["suggestion"])},
    "SelectMessageType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select message type"])},
    "MessageType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message type"])},
    "message_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message title"])},
    "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["language"])},
    "header": {
      "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
      "myOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My orders"])},
      "wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wallet"])},
      "help_center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help menu"])},
      "ourService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our service"])},
      "usageSteps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usage steps"])},
      "joinUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join us"])},
      "CustomerOpinions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer opinions"])},
      "app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App"])},
      "Contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us"])},
      "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About the platform"])},
      "how": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How we are"])},
      "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
      "notificationSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notification Settings"])},
      "fqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQs"])},
      "termsConditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms and conditions"])},
      "logOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log out"])},
      "notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notification"])}
    },
    "footer": {
      "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribe To Mail"])},
      "sendToUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Send to us"])}
    },
    "auth": {
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
      "problemMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you having trouble registering?"])},
      "registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration"])},
      "reset_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset password"])},
      "signUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up"])},
      "dontHaveAcc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Don't have an account? "])},
      "forgetPassord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forget password ?"])},
      "visitor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Login as a visitor"])},
      "haveAcc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" you have an account ? "])},
      "phoneOtp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The activation code sent to you via mobile phone"])},
      "reSend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resend the code"])},
      "completeData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete the information"])},
      "userName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User name"])},
      "writeUserName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please write user name"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "SelectCity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a City"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
      "writePhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please write Phone"])},
      "newPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New phone number"])},
      "writeNewPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter new phone number"])},
      "writeEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please write Email"])},
      "ContryCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country code"])},
      "City": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
      "enterOrderDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter order details here"])},
      "providerType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provider type"])},
      "SelectProviderType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select provider type"])},
      "attachImg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attach images"])},
      "attachFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attach files"])},
      "orderDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order date"])},
      "selectDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select service date"])},
      "BankAccountNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank account number"])},
      "enterBankAccountNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter bank account number"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["password"])},
      "enterPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter password"])},
      "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm password"])},
      "enterConfirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter confirm password"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
      "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject"])},
      "enterSubject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the message subject"])},
      "messageDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message details"])},
      "enterMessageDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your message here"])},
      "enterReportReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the reason"])},
      "ReasonComplaint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason for complaint"])},
      "reportDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complaint details"])},
      "enterReportDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the complaint details"])},
      "enterRateText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please write your comment"])},
      "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value"])}
    }
  },
  "orders": {
    "order_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order type"])},
    "deliver_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deliver location"])},
    "receiveOffers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receive offers"])},
    "currentRequests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current requests"])},
    "expiredOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expired orders"])},
    "orderDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order details"])},
    "RequestDesign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order a new design"])},
    "implementationRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New implementation request"])},
    "finance_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finance details"])},
    "shipping_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping price"])},
    "tax_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax price"])},
    "produts_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produts price"])},
    "orderImplementation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Execution request"])},
    "orderNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order number"])},
    "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "showMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show more"])},
    "onWay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On way"])},
    "inProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In progress"])},
    "Done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done"])},
    "orderInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order information"])},
    "mediaAttach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attachments"])},
    "priceOffers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price offers"])},
    "Accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept"])},
    "reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reject"])},
    "rateProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products rating"])},
    "orderCost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order cost"])},
    "orderValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order value"])},
    "addedValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Added value"])},
    "Total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "Chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversation"])},
    "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report"])},
    "acceptModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept offer and payment"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pay"])},
    "sendReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send report"])},
    "howExperience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How was your experience with"])},
    "sendRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
    "cancelling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelling order"])},
    "OfferValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer value"])},
    "new_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New order"])},
    "new_order_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A new request has been sent, request no"])},
    "accpet_wite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting for approval"])},
    "accept_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request Accept"])},
    "accept_order_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The store has accepted order no"])},
    "on_way": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our representative is on the way to you"])},
    "on_way_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A representative has been sent to you to review your order no"])},
    "finish_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finish a request"])},
    "finish_order_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request No. has been completed"])},
    "wait_pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Waiting for payment"])},
    "wait_pay_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting for payment for order no"])},
    "rate_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rate the product"])}
  },
  "profile": {
    "removeAccout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete account"])},
    "saveChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save changes"])},
    "change_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change_password"])},
    "changePhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change phone number"])},
    "deleteConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to delete account?"])},
    "goBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back off"])},
    "confirmDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm , delete"])},
    "activation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activation code"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
    "resendCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resend the code"])},
    "Continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
    "Continued": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
    "notifyWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please allow notifications to receive all new updates"])},
    "enableNotify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate notifications"])},
    "selectTypeOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose order type"])},
    "selectLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select location"])},
    "old_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Old password"])},
    "new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password"])},
    "confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm new password"])},
    "check_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your current password"])},
    "Confirm_identity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm identity"])},
    "active_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activation code"])},
    "active_code_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the inquiry code"])},
    "activation_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate account"])}
  }
}