<template>
  <v-app :class="{ 'isNotFound': isNotFound }">
    <site-header />
    <v-main>
      <div class="mainCotant">
        <router-view v-slot="{ Component }">
          <transition name="route" mode="out-in">
            <component :is="Component"></component>
          </transition>
        </router-view>
      </div>
      <div class="social-options">
        <a target="_blank" :href="`http://wa.me/${_data.whatsapp}`">
          <span>
            <img :src="require('@/assets/images/whatsapp_option.png')" alt="phon">
          </span>
        </a>
        <a :href="`tel:${_data.phone}`">
          <span><img :src="require('@/assets/images/call.png')" alt="phon"></span>
        </a>
      </div>
    </v-main>
    <site-footer />
  </v-app>
</template>


<script setup>
//  ========================================================== imports
import siteHeader from '@/components/layouts/siteHeader.vue'
import siteFooter from '@/components/layouts/siteFooter.vue'
import { onMounted, onBeforeMount, ref, watch } from "vue";
import { useRoute } from 'vue-router';
import AOS from "aos";
import axios from 'axios';
import { response } from './response';

//  ======================================================== data
const route = useRoute();
const _data = ref({})
const isNotFound = ref(null)
watch(route, (routedVal) => {
  if (routedVal.name == 'notFound') {
    isNotFound.value = true
  } else {
    isNotFound.value = false
  }
})
//  ======================================================== methods
const get_data = async () => {
  await axios.get(`settings`).then((res) => {
    let status = response(res).status
    let data = response(res).data
    if (status === 'success') {
      _data.value = data
    }
  })
}
const generateIpAddress = () => {
  if (sessionStorage.getItem('ipAddress')) {
    return null;
  } else {
    let ipAddress = "";

    for (var i = 0; i < 3; i++) {
      let number = Math.floor(Math.random() * 1000);
      ipAddress += number.toString();

      if (i < 2) {
        ipAddress += ".";
      }
    }

    ipAddress += ".0";
    sessionStorage.setItem('ipAddress', ipAddress)
    return ipAddress;
  }
}

//  ======================================================== lifcycle hooks
onBeforeMount(() => {
  if (localStorage.getItem('locale') == 'en') {
    document.getElementsByTagName('html')[0].setAttribute('lang', 'en')
  } else {
    document.getElementsByTagName('html')[0].setAttribute('lang', 'ar')
  }
})

onMounted(() => {
  get_data()
  generateIpAddress()
  AOS.init();
})

// var chatbox = document.getElementById('fb-customer-chat');
// chatbox.setAttribute("page_id", "176298748902024");
// chatbox.setAttribute("attribution", "biz_inbox");
// window.fbAsyncInit = function () {
//   FB.init({
//     xfbml: true,
//     version: 'v18.0'
//   });
// };

// (function (d, s, id) {
//   var js, fjs = d.getElementsByTagName(s)[0];
//   if (d.getElementById(id)) return;
//   js = d.createElement(s); js.id = id;
//   js.src = 'https://connect.facebook.net/ar_AR/sdk/xfbml.customerchat.js';
//   fjs.parentNode.insertBefore(js, fjs);}(document, 'script', 'facebook-jssdk'));

</script>
<style lang="scss">
.p-component {
  font-family: 'main_font', sans-serif !important;
}

.p-dropdown-label {
  font-family: 'main_font', sans-serif !important;
}

.v-application {
  direction: rtl !important;
  font-family: 'main_font', sans-serif !important;
}

.v-overlay__content,
.v-card--reveal {
  font-family: 'main_font', sans-serif !important;
}


#app {
  direction: rtl !important;
  font-family: 'main_font', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html[lang="en"] {
  #app {
    direction: ltr !important;
  }

  .v-application {
    direction: ltr !important;
  }
}
.v-main{
  position: relative;
}
@font-face {
  font-family: 'main_font_light';
  src: url('@/assets/webfonts/29ltbukralight.otf');
}

.font-light {
  font-family: 'main_font_light', sans-serif !important;
}

@font-face {
  font-family: 'main_font';
  src: url('@/assets/webfonts/29ltbukraregular.otf');
}

.pageView-enter-active {
  animation: fadeRight .5s forwards;
}

.pageView-leave-active {
  animation: fadeRight 0.5s reverse;
}

.p-dialog-mask.p-component-overlay {
  z-index: 999 !important;
}

.route-enter-from {
  opacity: 0;
  transform: translateX(-40px);
}

.route-leave-to {
  opacity: 0;
  transform: translateX(40px);
}

.route-enter-active {
  transition: all 0.3s ease-out;
}

.route-leave-active {
  transition: all 0.3s ease-in;
}

.route-enter-to,
.route-leave-from {
  opacity: 1;
  transform: translateX(0);
}

.p-dialog {
  overflow: hidden;
  border-radius: 20px !important;
}
</style>
