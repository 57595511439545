export default {
  "layout": {
    "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غير موجود"])},
    "backHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجوع للرئيسيه"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرئيسيه"])},
    "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنتجات"])},
    "more_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المزيد من المنتجات"])},
    "more_categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المزيد من الاقسام"])},
    "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاقسام"])},
    "favorite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المفضلة"])},
    "add_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضف للسلة"])},
    "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض"])},
    "offers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العروض"])},
    "get_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احصل علي العرض"])},
    "Contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تواصل معنا"])},
    "my_adresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عناويني"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البيانات الشخصية"])},
    "quickLinks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["روابط سريعة"])},
    "contactInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات التواصل"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لتحميل التطبيق"])},
    "filterPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فلتر حسب السعر"])},
    "filterBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فلتر حسب"])},
    "filterUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حسب الاعلي"])},
    "filterDown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حسب الاقل"])},
    "filterRating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فلتر حسب التقييم"])},
    "filterCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فلتر حسب القسم"])},
    "products_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفاصيل المنتج"])},
    "cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السلة"])},
    "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنتج"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السعر"])},
    "Quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكمية"])},
    "total_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السعر الإجمالي"])},
    "add_new_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة عنوان جديد"])},
    "edit_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل العنوان"])},
    "choose_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختيار عنوان التوصيل"])},
    "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الموقع"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحديد"])},
    "address_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إسم العنوان"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العنوان"])},
    "addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العناوين"])},
    "address_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفاصيل العنوان"])},
    "set_default_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعيين العنوان الافتراضي"])},
    "add_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة العنوان"])},
    "select_pay_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حدد طريقة الدفع"])},
    "Pay_recieving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدفع عند الاستلام"])},
    "Pay_electronic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدفع بالبطاقة الآلكترونية"])},
    "complete_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استكمال الطلب"])},
    "shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشحن"])},
    "tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الضريبة"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل"])},
    "no_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد منتجات"])},
    "similar_products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منتجات ذات صلة"])},
    "enter_coupon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل الكوبون"])},
    "have_coupon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل لديك كوبون ؟"])},
    "no_notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد اشعارات"])},
    "remove_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف الكل"])},
    "online_trans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" إضافة رصيد أونلاين "])},
    "complain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شكوي"])},
    "suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إقتراح"])},
    "SelectMessageType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر موضوع الرسالة"])},
    "MessageType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موضوع الرسالة"])},
    "message_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان الرسالة"])},
    "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اللغة"])},
    "header": {
      "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرئيسيه"])},
      "myOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلباتي"])},
      "wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المحفظه"])},
      "help_center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قائمة المساعده"])},
      "ourService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خدماتنا"])},
      "usageSteps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطوات الاستخدام"])},
      "joinUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انضم إلينا"])},
      "CustomerOpinions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أراء العملاء"])},
      "app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التطبيق"])},
      "Contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تواصل معنا"])},
      "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عن المنصة"])},
      "how": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من نحن"])},
      "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الملف الشخصي"])},
      "notificationSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعدادات الإشعارات"])},
      "fqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أسئلة متكررة"])},
      "termsConditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشروط والأحكام"])},
      "logOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الخروج"])},
      "notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإشعارات"])}
    },
    "footer": {
      "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اشترك في البريد"])},
      "sendToUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ارسل لنا"])}
    },
    "auth": {
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الدخول"])},
      "problemMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" هل تواجه مشكلة بالتسجيل ؟ "])},
      "registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل جديد"])},
      "reset_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إسترجاع كلمة المرور"])},
      "signUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل"])},
      "dontHaveAcc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" ليس لديك حساب ؟ "])},
      "forgetPassord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نسيت كلمة المرور ؟"])},
      "visitor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" الدخول كزائر "])},
      "haveAcc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" لديك حساب ؟ "])},
      "phoneOtp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رمز التفعيل المرسل إليك عبر الجوال"])},
      "reSend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعادة ارسال الرمز"])},
      "completeData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إستكمال البيانات"])},
      "userName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إسم المستخدم"])},
      "writeUserName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجي إدخال إسم المستخدم"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد الإلكتروني"])},
      "SelectCity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر المدينه"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الجوال"])},
      "writePhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجي إدخال رقم الجوال"])},
      "newPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الجوال الجديد"])},
      "writeNewPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجي إدخال رقم الجوال الجديد"])},
      "writeEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجي إدخال البريد الإلكتروني"])},
      "ContryCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كود الدوله"])},
      "City": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المدينة"])},
      "enterOrderDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجي كتابة تفاصيل الطلب"])},
      "providerType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع مقدم الخدمة"])},
      "SelectProviderType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر نوع مقدم الخدمة"])},
      "attachImg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إرفاق صور"])},
      "attachFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إرفاق ملفات"])},
      "orderDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الطلب"])},
      "selectDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجي تحديد تاريخ الخدمة"])},
      "BankAccountNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الحساب البنكي"])},
      "enterBankAccountNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجي إدخال رقم الحساب البنكي"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور"])},
      "enterPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجي إدخال كلمة المرور"])},
      "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد كلمة المرور"])},
      "enterConfirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجي إدخال تأكيد كلمة المرور"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إرسال"])},
      "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موضوع الرسالة"])},
      "enterSubject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجي إدخال موضوع الرسالة"])},
      "messageDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفاصيل الرسالة"])},
      "enterMessageDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجي إدخال رسالتك هنا"])},
      "enterReportReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجي إدخال سبب الشكوي"])},
      "ReasonComplaint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سبب الشكوي"])},
      "reportDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفاصيل الشكوي"])},
      "enterReportDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجي إدخال تفاصيل الشكوي"])},
      "enterRateText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجي كتابة تعليقك"])},
      "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القيمة"])}
    }
  },
  "orders": {
    "order_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الطلب"])},
    "deliver_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان التوصيل"])},
    "receiveOffers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تلقي العروض"])},
    "currentRequests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطلبات الحالية"])},
    "expiredOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطلبات المنتهية"])},
    "orderDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفاصيل الطلب"])},
    "RequestDesign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلب تصميم جديد"])},
    "implementationRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلب تنفيذ جديد"])},
    "finance_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التفاصيل المالية"])},
    "shipping_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سعر الشحن"])},
    "tax_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سعر الضريبة"])},
    "produts_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سعر المنتجات"])},
    "orderDesign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلب تصميم"])},
    "orderImplementation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلب تنفيذ"])},
    "orderNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الطلب"])},
    "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحالة"])},
    "showMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض المزيد"])},
    "onWay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["في الطريق"])},
    "inProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قيد التنفيذ"])},
    "Done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم الانتهاء"])},
    "orderInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات الطلب"])},
    "mediaAttach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصور و المرفقات"])},
    "priceOffers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عروض الاسعار"])},
    "Accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قبول"])},
    "reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رفض"])},
    "rateProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقييم المنتجات"])},
    "orderCost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تكلفة الطلب"])},
    "orderValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قيمة الطلب"])},
    "addedValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القيمة المضافة"])},
    "Total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإجمالي"])},
    "Chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إجراء محادثة"])},
    "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إبلاغ"])},
    "acceptModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قبول عرض السعر والدفع"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدفع"])},
    "sendReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ارسل البلاغ"])},
    "howExperience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كيف كانت تجربتك مع"])},
    "sendRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إرسال التقييم"])},
    "cancelling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلغاء الطلب"])},
    "OfferValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قيمة العرض"])},
    "new_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلب جديد"])},
    "new_order_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم إرسال طلب جديد الطلب رقم"])},
    "accpet_wite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بإنتظار الموافقه"])},
    "accept_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قبول الطلب"])},
    "accept_order_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قام المتجر بقبول طلب رقم"])},
    "on_way": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مندوبنا في الطريق إليك"])},
    "on_way_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم إرسال مندوب إليك لمراجعة طلبك رقم"])},
    "finish_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنهاء طلب"])},
    "finish_order_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم الانتهاء من الطلب رقم"])},
    "wait_pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["في انتظار الدفع"])},
    "wait_pay_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["في انتظار الدفع للطلب رقم"])},
    "rate_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم بتقييم المنتج"])}
  },
  "profile": {
    "removeAccout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف الحساب"])},
    "saveChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ التغييرات"])},
    "changePhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تغيير رقم الجوال"])},
    "change_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تغيير كلمة المرور"])},
    "deleteConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل تريد حذف حسابك ؟"])},
    "goBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التراجع"])},
    "confirmDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نعم , احذف"])},
    "activation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رمز التفعيل"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد"])},
    "resendCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعادة ارسال الرمز"])},
    "Continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متابعة"])},
    "Continued": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استمرار"])},
    "notifyWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى السماح للإشعارات ليصلك كل جديد"])},
    "enableNotify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفعيل الإشعارات"])},
    "selectTypeOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر نوع الطلب"])},
    "selectLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجي تحديد الموقع"])},
    "old_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور القديمة"])},
    "new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور الجديده"])},
    "confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد كلمة المرور الجديده"])},
    "check_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم بإدخال كلمة المرور الحالية"])},
    "Confirm_identity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد الهوية"])},
    "active_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كود التحقق"])},
    "active_code_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء إدخال رمز التحقيق"])},
    "activation_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفعيل الحساب"])}
  }
}