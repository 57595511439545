import { createApp } from 'vue';
import App from './App.vue';
import router from './router';

import 'material-design-icons-iconfont/dist/material-design-icons.css';

import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';
import Toast from 'primevue/toast';
import "primevue/resources/themes/lara-light-indigo/theme.css";
import BadgeDirective from 'primevue/badgedirective';
import Badge from 'primevue/badge';
import Skeleton from 'primevue/skeleton';

// import lightbox
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
Fancybox.bind('[data-fancybox]', {
  //
});

// import fontawesome 
import { library, dom } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';

library.add(fas, far, fab)
dom.watch();

// import bootstrap 
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'

// import primeflex 
import '/node_modules/primeflex/primeflex.css'

// import i18n 
import VueI18n from 'vue-i18n';
import i18n from './i18n';

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const vuetify = createVuetify({
  components,
  directives,
})

// import my scss
import '@/assets/SCSS/styles.scss'
import '@/assets/SCSS/assets.scss'
// import '@/assets/SCSS/auth.scss'
// import '@/assets/SCSS/chat.scss'
import '@/assets/SCSS/styles-ltr.scss'



// importing AOS css style globally
import 'aos/dist/aos.css'
// import animate css
import 'animate.css';



// import axios 
import axios from 'axios';
axios.defaults.baseURL = 'https://backend.cosmo-land.com/api/'
// axios.defaults.headers['x-api-key'] = '3520e7c92eeb2b9953524b0c865884d7';
axios.defaults.headers.common['lang'] = `${localStorage.getItem('locale')}`;

// social share 
import VueSocialSharing from 'vue-social-sharing'
// GoogleMap
import GoogleMap from 'vue-google-maps-ui'

// createApp
createApp(App)
  .use(router)
  .component("font-awesome-icon", FontAwesomeIcon)
  .component('Toast', Toast)
  .component('Googlemap', GoogleMap)
  .component('Badge', Badge)
  .directive('badge', BadgeDirective)
  .component('Skeleton', Skeleton)
  .use(i18n)
  .use(VueSocialSharing)
  .use(VueI18n)
  .use(ToastService)
  .use(vuetify)
  .use(PrimeVue)
  .mount('#app')