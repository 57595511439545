<template>
  <footer>
    <div class="container">
      <div class="grid">
        <div class="col-12 sm:col-6 md:col-4">
          <div class="footer-list">
            <h6 class="fontBold M_bottom_15">{{ $t('layout.quickLinks') }}</h6>
            <ul>
              <li class="move">
                <span><img :src="require('@/assets/images/blog-icon.png')" alt="blog"></span>
                <router-link to="/AboutView">{{ $t('layout.header.about') }}</router-link>
              </li>
              <li class="move">
                <span><img :src="require('@/assets/images/qs-icon.png')" alt="qs"></span>
                <router-link to="/Faqs">{{ $t('layout.header.fqs') }}</router-link>
              </li>
              <li class="move">
                <span><img :src="require('@/assets/images/contac-icon.png')" alt="contac"></span>
                <router-link to="/ContactUs">{{ $t('layout.header.Contact') }}</router-link>
              </li>
              <li class="move">
                <span><img :src="require('@/assets/images/terms-and-conditions.png')" alt="contac"></span>
                <router-link to="/Terms">{{ $t('layout.header.termsConditions') }}</router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-12 sm:col-6 md:col-4">
          <div class="footer-list">
            <h6 class="fontBold M_bottom_15">{{ $t('layout.contactInfo') }}</h6>
            <ul>
              <li class="move">
                <span><img :src="require('@/assets/images/whatsapp.png')" alt="phon"></span>
                <a target="_blank" :href="`http://wa.me/${_data.whatsapp}`">{{ _data.whatsapp }}</a>
              </li>
              <li class="move">
                <span><img :src="require('@/assets/images/phon-icon.png')" alt="phon"></span>
                <a :href="`tel:${_data.phone}`">{{ _data.phone }}</a>
              </li>
              <li class="move">
                <span><img :src="require('@/assets/images/coment-icon.png')" alt="coment"></span>
                <a href="mailto:sample@aait.com">{{ _data.email }}</a>
              </li>
              <!-- <li class="move">
                <span><img :src="require('@/assets/images/location-icon.png')" alt="location"></span>
                <span>الرياض , اسم الحي , الشارع</span>
              </li> -->
              <li class="gap20 mt-10">
                <a v-for="item in socials_data" :key="item"
                 class="raise" :href="item.link" target="_blank"><img class="width25" :src="item.icon" :alt="item.name"></a>
                 <!-- <a class="raise" href=""><img class="width25" :src="require('@/assets/images/whatsapp.png')" alt=""></a> -->
              </li>
            </ul>
          </div>
        </div>
        <div class="col-12 sm:col-6 md:col-4">
          <router-link to="/"><img class="w-per-60" :src="`https://backend.cosmo-land.com/${_data.logo}`" alt=""></router-link>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup>
// ================================================================ imports
import { response } from "@/response";
import axios from "axios";
import { onMounted, ref } from "vue";

// ================================================================ data
const _data = ref({})
const socials_data = ref([])
// ================================================================ methods
const get_data = async()=>{
  await axios.get(`settings`).then((res)=>{
    let status = response(res).status
    let data = response(res).data
    if(status === 'success'){
      _data.value = data
    }
  })
}

const get_socials = async()=>{
  await axios.get(`socials`).then((res)=>{
    let status = response(res).status
    let data = response(res).data
    if(status === 'success'){
      socials_data.value = data
    }
  })
}
// ================================================================ lifecycle hooks
onMounted(()=>{
  get_socials()
  get_data()
})

</script>

<style></style>